import { getFormatter } from './dateFormatter.js';
/**
 * Returns a value no smaller than min and no larger than max.
 *
 * @param {Date} value Value to return.
 * @param {Date} min Minimum return value.
 * @param {Date} max Maximum return value.
 * @returns {Date} Value between min and max.
 */
export function between(value, min, max) {
    if (min && min > value) {
        return min;
    }
    if (max && max < value) {
        return max;
    }
    return value;
}
var nines = ['9', '٩'];
var ninesRegExp = new RegExp("[".concat(nines.join(''), "]"));
var amPmFormatter = getFormatter({ hour: 'numeric' });
export function getAmPmLabels(locale) {
    var amString = amPmFormatter(locale, new Date(2017, 0, 1, 9));
    var pmString = amPmFormatter(locale, new Date(2017, 0, 1, 21));
    var _a = amString.split(ninesRegExp), am1 = _a[0], am2 = _a[1];
    var _b = pmString.split(ninesRegExp), pm1 = _b[0], pm2 = _b[1];
    if (pm2 !== undefined) {
        // If pm2 is undefined, nine was not found in pmString - this locale is not using 12-hour time
        if (am1 !== pm1) {
            return [am1, pm1].map(function (el) { return el.trim(); });
        }
        if (am2 !== pm2) {
            return [am2, pm2].map(function (el) { return el.trim(); });
        }
    }
    // Fallback
    return ['AM', 'PM'];
}
