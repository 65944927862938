import config from '/config'
export const url = config.ecr
export const dockerRegistry = config.dockerRegistry


import { handleErrors } from '../fetch-utils'

import Auth from '../auth/auth'

const __token = Auth.token

const options = {
  headers: __token ? {
    Authorization: `sage ${__token}`
  } : {}
}



function get(endpoint: string) {
  return fetch(endpoint, options)
    .then(handleErrors)
    .then(res => res.json())
}

function post(endpoint: string, data = '') {
  return fetch(endpoint, {
    method: 'POST',
    body: data,
    ...options
  }).then(handleErrors)
    .then(res => res.json())
}

function put(endpoint: string, data = {}) {
  return fetch(endpoint, {
    method: 'PUT',
    body: JSON.stringify(data),
    ...options
  }).then(handleErrors)
    .then(res => res.json())
}

function deleteReq(endpoint: string) {
  return fetch(endpoint, {
    method: 'DELETE',
    ...options
  }).then(handleErrors)
    .then(res => res.json())
}



export type App = {
  namespace: string,
  name: string
  version: string
}

type Arch =
  'linux/amd64' |
  'linux/arm64' |
  'linux/arm/v6' |
  'linux/arm/v7' |
  'linux/arm/v8'


export type AppMeta = {
  namespace?: string
  name?: string
  version?: string
  description?: string
  authors?: string // todo(nc) allow list too?
  keywords?: string
  homepage?: string
  license?: string
  funding?: string
  collaborators?: string // todo(nc): allow list too?
  thumbnail?: string[] // todo(nc): make single string
  images?: string[]
  science_description?: string
  source: {
    architectures: Arch[]
    url: string
    branch: string
    directory?: string
    dockerfile?: string
    build_args?: {
      [variable: string]: string
    }
  }
  resources?: {
    type: string,
    view: string
  }[]
  inputs?: {
    id: string,
    type: 'boolean' | 'int' | 'long' | 'float' | 'double' | 'string' | 'File'
    description: string
  }[]
  metadata?: {
    [item: string]: any
  }
}

export type AppDetails =
  AppMeta & {
    id: string
    owner: string
    frozen: boolean
    time_created: string
    time_last_updated: string

    // addition status info, requested separate from app details or derived
    isBuilding?: boolean
    buildResult?: string
    buildUrl?: string
    hasRecentData?: boolean
  }


export type Repo = {
  namespace: string
  name: string
  owener_id: string
  versions: AppDetails[]
}


export function register(appConfig: string) {
  return post(`${url}/submit`, appConfig)
}


export function build(app: App, skipPush = false) {
  const {namespace, name, version} = app
  return post(`${url}/builds/${namespace}/${name}/${version}${skipPush ? `?skip_image_push=true` : ''}`)
}


export async function registerAndBuild(appConfig: string) {
  // note registerRes will contain the version generated by server if one was not provided
  const registerRes = await register(appConfig)
  const buildRes = await build(registerRes)
  return buildRes
}


export async function deleteApp(app: App) {
  const {namespace, name, version} = app
  const res = await deleteReq(`${url}/apps/${namespace}/${name}/${version}`)
  return res
}


export async function deleteRepo(repo) {
  const {namespace, name} = repo

  // first, delete all versions
  const versions = await listVersions(repo)
  const proms = versions.map(({version}) => deleteApp({namespace, name, version}))

  // delete repo
  const res = Promise.all(proms)
    .then(() => deleteReq(`${url}/repositories/${namespace}/${name}`))

  return res
}


/**
 * permissions
 */

export type Permission =  'READ' | 'WRITE' | 'READ_ACP' | 'WRITE_ACP' | 'FULL_CONTROL'

export type PermissionObj = {
  grantee: string
  granteeType: GranteeType
  permission: Permission
  resourceName: string
  resourceType: 'string'
}

type Operation = 'add' | 'delete'
type GranteeType = 'USER' | 'GROUP'


export function makePublic(repo: Repo, operation: Operation = 'add') {
  const {namespace, name} = repo
  return put(`${url}/permissions/${namespace}/${name}`, {
    operation,
    granteeType: 'GROUP',
    grantee: 'AllUsers',
    permission: 'READ'
  })
}


export function share(
  repo: Repo,
  grantee: string,
  permission: Permission,
  operation: Operation = 'add'
) {
  const {namespace, name} = repo
  return put(`${url}/permissions/${namespace}/${name}`, {
    operation,
    granteeType: 'USER',
    grantee,
    permission
  })
}

export function listPermissions(repo: Repo) : Promise<PermissionObj[]> {
  const {namespace, name} = repo
  return get(`${url}/permissions/${namespace}/${name}`)
}



/**
 * listings
 */

type Namespace = {
  id: string
  owner_id: string
  type: string
}

export function listNamespaces() : Promise<Namespace[]>{
  return get(`${url}/apps`)
    .then(data => data.data)
}


export function listVersions(repo: Repo) : Promise<AppDetails[]> {
  const {namespace, name} = repo
  return get(`${url}/apps/${namespace}/${name}`)
    .then(data => data.data)
    .then(d => d.sort(timeCompare))
}

type FilterType = 'mine' | 'public'
export async function listApps(filter: FilterType) : Promise<AppDetails[]> {
  const repoFilters = [
    __token ? `view=permissions` : '',
    filter == 'mine' ? `nopublic=true` : ''
  ]

  const appFilters = [
    filter == 'public' ? 'public=true' : '',
    filter == 'mine' ? 'nopublic=true' : ''
  ]

  const repoPerm = get(`${url}/repositories?${repoFilters.join('&')}`)
  const appProm = get(`${url}/apps?${appFilters.join('&')}`)

  return Promise.all([repoPerm, appProm])
    .then(([repoRes, appRes]) => {

      const repos = repoRes.data

      // create lookup; todo?: could do merging on repos instead
      const repoMap = repos.reduce((acc, r) =>
        (`${r.namepsace}/${r.name}` in acc) ?
          acc : {...acc, [`${r.namespace}/${r.name}`]: r}
      , {})

      // sort by last updated
      const allApps =
        appRes.data.sort(timeCompare)

      // reduce to last updated (and get versions)
      const versions = {}
      let apps = allApps.reduce((acc, app) => {
        const [repo, ver] = app.id.split(':')

        if (repo in versions) {
          versions[repo].push(ver)
          return acc
        } else {
          versions[repo] = [ver]
          return [...acc, app]
        }
      }, [])

      // ignore any apps not in repoMap, and merge in additional data
      apps = apps
        .filter(app => app.id.split(':')[0] in repoMap)
        .map(app => {
          const repo = app.id.split(':')[0]
          const {permissions = []} = repoMap[repo]
          const isPublic = !!permissions.find(p => p.resourceType != 'namespace' && p.grantee === 'AllUsers')

          return {
            ...app,
            versions: versions[repo],
            permissions,
            isPublic,
            isShared: false
          }
        })

      return apps
    })
}


export function getBuildStatus(app: App) {
  const {namespace, name, version} = app
  return get(`${url}/builds/${namespace}/${name}/${version}`)
}


export function listBuildStatus(apps: App[]) {
  const proms = apps.map(app => getBuildStatus(app))
  return Promise.all(proms)
}



/**
 * retrieval
 */

export function getRepo(repo) : Promise<Repo> {
  const {namespace, name} : {namespace: string, name: string} = repo
  return get(`${url}/repositories/${namespace}/${name}`)
    .then(data => {

      return {
        ...data,
        versions: data.versions.sort(timeCompare)
      }
    })
}

export function getAppConfig(app: App) : Promise<AppMeta> {
  // todo(wg): add `view=app` for repos(?)
  const {namespace, name, version} = app
  return get(`${url}/apps/${namespace}/${name}/${version}?view=app`)
}

export function getAppDetails(app: App) : Promise<AppDetails> {
  const {namespace, name, version} = app
  return get(`${url}/apps/${namespace}/${name}/${version}`)
}

export function getSciMarkdown(path: string) {
  return fetch(`${url}/meta-files/${path}`, options)
    .then(handleErrors)
    .then(res => res.text())
}


/**
 * helpers
 */

export function isPublic(permissions: PermissionObj[]) : boolean {
  return (permissions || []).filter(p => p.grantee === 'AllUsers').length > 0
}


const timeCompare = (a, b) =>
  b.time_last_updated.localeCompare(a.time_last_updated)



export const repoIsPublic = (publicApps: AppDetails[], name: string) =>
  publicApps.find(o => o.id.includes(name))




